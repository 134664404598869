import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import withRouter from "components/Common/withRouter"
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  UncontrolledTooltip,
  Table,
} from "reactstrap"
import Breadcrumbs from "components/Common/Breadcrumb"
import {
  AdminLoginUser,
  GetUsers,
  LoginUser,
  UpdateUserStatus,
} from "helpers/backend_helper"
import { useLoading } from "helpers/custom_hooks"
import DeclineModal from "./DeclineModal"
import ConfirmationModal from "./ConfirmationModal"
import UserEditModal from "./UserEditModal"
import moment from "moment"

const Users = props => {
  const [selectedUser, setSelectedUser] = useState()
  const [users, setUsers] = useState([])
  const [error, setError] = useState()
  const [companyInfoModal, setUserEditModal] = useState(false)
  const [declineModal, setDeclineModal] = useState(false)
  const [acceptModal, setAcceptModal] = useState(false)
  const setLoading = useLoading()

  const onStatusUpdateClick = (user, acceptUser = false) => {
    setSelectedUser(user)

    if (acceptUser) setAcceptModal(true)
    else setDeclineModal(true)
  }

  const handleStatusUpdate = async approve => {
    setLoading(true)

    try {
      await UpdateUserStatus({
        userID: selectedUser.id,
        status: approve ? "active" : "declined",
      })
      loadData()
      setDeclineModal(false)
      setAcceptModal(false)
    } catch (error) {
      alert("An unexpected error happened!")
      console.log(error)
    }
  }

  const handleLogin = async user => {
    try {
      setLoading(true)
      const result = await AdminLoginUser(user?.id)
      const url = new URL(process.env.REACT_APP_APP_URL + "/login")
      url.searchParams.append("authUser", result)
      window.open(url, "_blank")
    } catch (err) {
      err?.response?.status == 400
        ? alert(err.response.data, setError)
        : alert("An unhandled exception happend!", setError)
    }
    setLoading(false)
  }

  useEffect(() => {
    loadData()
  }, [])

  const loadData = async () => {
    setLoading(true)
    let result = await GetUsers()
    result = result.sort((a, b) => {
      const statusOrder = {
        confirmed: 1,
        "": 2,
        active: 2,
        declined: 3,
      }

      return statusOrder[a.status] - statusOrder[b.status]
    })

    setUsers(result)
    setSelectedUser()
    setLoading(false)
  }

  const getRowColor = user => {
    if (user.status == "active" && !user.trialUntil) return "bg-success"

    if (user.status == "active" && moment(user.trialUntil).isAfter(moment()))
      return "bg-success"

    if (user.status == "declined") return "bg-dark"

    if (user.status == "confirmed") return "bg-warning"

    if (user.status == "pending payment") return "bg-info"

    return "bg-danger"
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Users" breadcrumbItem="User List" />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <Table>
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Phone Nr.</th>
                        <th>Company</th>
                        <th>Status</th>
                        <th className="text-center">Trial Until</th>
                        <th>Note</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {users.length === 0 && (
                        <tr>
                          <td colSpan={5} className="text-center">
                            There are currently no users.
                          </td>
                        </tr>
                      )}
                      {users.map((user, index) => (
                        <tr
                          key={index}
                          className={getRowColor(user) + " bg-opacity-25 "}
                        >
                          <td className="align-middle">
                            {user.firstname} {user.lastname}
                          </td>
                          <td className="align-middle">
                            <a
                              className="text-dark"
                              href={"mailto:" + user.email}
                            >
                              {user.email}
                            </a>
                          </td>
                          <td className="align-middle">{user.phoneNumber}</td>
                          <td className="align-middle">{user.name}</td>
                          <td className="align-middle">{user.status}</td>
                          <td className="align-middle">{user.accountType}</td>
                          <td className="align-middle text-center">
                            {user.trialUntil
                              ? moment(user.trialUntil).format("DD.MM.YYYY")
                              : "-"}
                          </td>
                          <td className="align-middle text-center">
                            {user.lastInvoice
                              ? moment(user.lastInvoice).format("DD.MM.YYYY")
                              : "-"}
                          </td>
                          <td className="align-middle">{user.billingPeriod}</td>

                          <td className="align-middle">{user.note}</td>

                          <td className="align-middle">
                            <button
                              className="btn btn-primary btn-sm"
                              onClick={_ => {
                                setSelectedUser(user)
                                setUserEditModal(true)
                              }}
                            >
                              Edit User
                            </button>
                            <button
                              className="btn btn-dark btn-sm ms-2"
                              onClick={_ => {
                                handleLogin(user)
                              }}
                            >
                              Login
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <UserEditModal
        user={selectedUser}
        modal={companyInfoModal}
        close={_ => {
          loadData()
          setUserEditModal(false)
        }}
      />
    </React.Fragment>
  )
}

export default withRouter(Users)
